
.SlidePages{
    display: flex;
  align-items: center;
  justify-content: center;
}
.InstructionSlides{
    width: 100%;
}
.image {
 display: flex;
justify-content: center;
    width: 90%;
    padding-left: 5%;
    padding-top:15%;
    height: auto;
    max-height: 90%;

}

.buttonsUnderSlides {
  display:flex;
justify-content: center;

  
}

.rightArrow{
  rotate: 180deg;
 font-size:140%;
}
.leftArrow{  
  font-size:140%; 
}

.SidePoseSlide{
  top: -1%;
}
