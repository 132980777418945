.image {
 display: flex;
justify-content: center;
 width: 80%;
 left: 10%;
 top:10%;
  height: auto;

}

.imageSide {
    width: 100%;
    top: -1%;
}


.buttonsUnderInstructions{
 display:flex;
justify-content: center;

 
}


