.resultPhoto {
    position: relative;
    width: 100%;
    height: 50vh;
    margin-inline: 0;
}

.subheader {

    display: grid;
    place-items: center;
    font-size: 1rem;
    text-align: center;
    text-transform: capitalize;
    z-index: 1;

}

.measureList {
    opacity: 0.6    ;
}

.measureBtns {
    opacity: 1;
}

.resultMeasurements {
    top: -5%;
    width: 121%;
    height: 65vh;
    margin-left: -15%;
    border-radius: 0% 1% 1% 0%;
    margin-bottom: 0%;
}


.iconSpace {
    margin-left: -12%;
    margin-bottom: -4%;
}

.bodyIcons {
    height: 18px;
    width: 18px;
    margin-top: 12px;
    scale: 1.5;
}

.bodyPartTitle {
    text-transform: capitalize;
    color: rgb(29, 12, 12);
}

.measureUnit {
    text-transform: lowercase;
    color: rgb(29, 12, 12);
    margin-top: -4%;

}

.measureBtns {
    height: 100%;
    width: 100%;
    display: grid;
    place-items: left;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    overflow: auto;
    row-gap: 0;
    margin-top: -4%;
}

.resultBtn {
    display: flex;
    justify-content: left;
    align-items: left;
    margin-top: -15px;
}

.measurementBtn {
    width: 100%;
    display: grid;
    place-items: left;
    text-align: left;
    font-size: 0.7rem;
    left: 2px;
    top: 2px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.measurementBtn div {
    padding-left : 4px;
}
div.overlay {
    position: absolute;
    top: 0;
    left: 0;
}

.selectedMeasurement {
    border-radius: 4px;
    background-color: 0x196e44;
    background-color: color-mix(in srgb, var(--ion-color-success-shade), white 15%);
}