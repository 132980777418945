.motionInfo {
  position: absolute;
  justify-content: center;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 1000;
  opacity: 0.8;
  margin: 0;
  padding: 0;
  font-size: 100px;
  width: 100%;
  height: 100%;
}

.motionInfo .icon-size.up {
  align-self: flex-start;
  margin-top: 0;
  color: red;
}

.motionInfo .icon-size.down {
  align-self: flex-end;
  margin-bottom: 0;
  color: red;
}

.overlay-container {
  position: absolute;
  width: 34%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}