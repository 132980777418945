.previewImageContainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: auto;
}

.previewImage {
  display: block;
  width: 100%;
  height: auto;
  margin: auto;
}

.previewMaskOverlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  overflow: hidden;
}

.previewMask {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  opacity: 50%;
  object-fit: contain;
}

.buttons{
  display:flex;
  justify-content: center;
  gap: 5%;
}
