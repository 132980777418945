.logoText {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 7%;
  bottom: 40%;
  transform: translateY(-40%);
font-family: 'Proxima Nova';
  letter-spacing: 12px;
  font-weight: 10; 
  font-size: 55px;
  color: #3828c9af;
  text-shadow:	
  -1px 1px 0 #a7a7a7,
  -2px 2px 0  #3e2fc5af,
  -3px 3px 0   black,
  -4px 4px 0 black;
  transition: all .2s ;
  line-height: 240px;
  transform: skew(-10deg);
}
.gdpr-alert .alert-wrapper {
  max-width: 480px;
}
.BckgrVideo{
 display: flex;
justify-content: center;
 width: 100%;
height: 100%;
opacity: 0.5;
    
}

.container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.container strong {
  font-size: 20px;
  line-height: 26px;
}

.container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

.container a {
  text-decoration: none;
}
