.infoBtn {
	text-align: center;

}

.titleToolbar {
	font-size: large;
	margin-top: auto;
	text-align: center;
	font-family: Arial, sans-serif;
	background-color: rgb(82, 82, 116);
	color: white;
}

.loadingIconText {
	text-align: center;
	position: absolute;
	left: 0;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
}


.loadingIcon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	scale: 5;
}

.loadingText {
	text-align: center;

}

.measurement {
	height: 4em;
	margin-top: 8px;
	margin-bottom: 8px;
}

.row-separation {
	display: block;
	margin: 4px 0;
}