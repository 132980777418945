.navImg{
    left: 10%;
    position: relative;
    max-height: 26px;
}
.logo-img::part(image) {
    max-height: 22px;
}
.logo-img{ 
    align-items: center;
}